import { Show } from "solid-js";
import type { Uuid } from "~/types/Uuid";
import { BROWSING_STATE, REPERTOIRE_STATE } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import {
	AnimatedBarPiece,
	AnimatedBarValue,
	type LabeledBarIdentifier,
	genLabeledBarKey,
} from "./AnimatedBarPiece";

export const BigBar = (props: {
	repertoireId?: Uuid;
	rounded?: boolean;
	isInSidebar?: boolean;
	large?: boolean;
	decimalPlaces?: number;
	type: "completion" | "mastery";
}) => {
	const progressState = () => {
		if (!props.repertoireId && props.type === "completion") {
			console.error("BigBar: no repertoire id for completion");
		}
		return BROWSING_STATE().repertoireProgressState[props.repertoireId!]!;
	};
	const mastery = () => {
		if (props.repertoireId) {
			return REPERTOIRE_STATE().masteryFromEpd[props.repertoireId][START_EPD];
		}
		return REPERTOIRE_STATE().masteryOverall;
	};
	const barValue = () => {
		let x = props.type === "completion" ? progressState().percentComplete : mastery();
		if (x > 0) {
			x = Math.max(0.02, x);
		}
		return x;
	};
	// const totalWidth = progressState().percentComplete * 100;
	return (
		<Show when={progressState() || props.type === "mastery"}>
			<div class="flex gap-3">
				<h2 class="font-semibold">
					<AnimatedBarValue
						decimalPlaces={props.decimalPlaces || 0}
						key={genLabeledBarKey(props.type as LabeledBarIdentifier, props.repertoireId)}
						value={
							(props.type === "completion" ? progressState().percentComplete : mastery()) * 100
						}
					/>{" "}
					{props.type === "completion" ? "completed" : "mastered"}
				</h2>
				<div
					class="bg-gray-22 h-2 rounded-sm overflow-hidden"
					style={stylex(c.relative, c.fullWidth, c.relative)}
				>
					<AnimatedBarPiece
						hideValue
						rounded
						classBar={clsx(props.type === "completion" ? "bg-green-45" : "bg-yellow-45")}
						key={genLabeledBarKey(props.type, props.repertoireId)}
						value={barValue()}
					/>
				</div>
			</div>
		</Show>
	);
};
